import React, { useEffect } from 'react';
import './index.sass';

interface IProps {
  open: boolean;
  children: React.ReactNode;
  onClose: () => void;
}

export const Modal = ({ open, children }: IProps) => {
  useEffect(() => {
    if (open) {
      document.querySelector('html').classList.add('is-dialog-open');
    } else {
      document.querySelector('html').classList.remove('is-dialog-open');
    }
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <div className="nv-modal-overlay">
      <div className="nv-modal-content">{children}</div>
    </div>
  );
};
