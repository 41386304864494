export const highlights: Array<{ title: string, description: string }> = [
  {
    title: 'Searching and custom filtering',
    description:
      "Search for any user in your organization by name, department, job title or other fields. Can't find what you're looking for? Configure your own filters!",
  },
  {
    title: 'Fully customizable templates',
    description:
      'Decide what information is displayed by changing your user template. Employee Directory comes with a special editor which allows you to show any field from a user profile.',
  },
  {
    title: ' Exceptional speed',
    description:
      'Employee Directory utilizes SharePoint search therefore, all data is indexed, security trimmed and immediately retrieved.',
  },
  {
    title: 'Bulletproof security',
    description:
      'Our app is hosted in your SharePoint environment. No data is sent to our servers or shared with any third parties.',
  },
  {
    title: 'User photos & Presence Indicator',
    description:
      'The user photos are loaded either from SharePoint user profiles or Office 365, whichever is available. It works automatically out of the box. The presence indicator tells you whether a user is available.',
  },
  {
    title: 'Multilingual support',
    description:
      'Our contact search comes with a translation file which means all labels and messages can be updated to any language. Currently, the app comes with the following languages: English, Czech, French, German, Danish, and Spanish.',
  },
  {
    title: 'Multiple user views',
    description:
      'Search results can be displayed in a user card or table view. The table view can be printed in a customizable template.',
  },
  {
    title: 'Recently modified documents',
    description:
      'Recently modified documents can be displayed and accessed for any user.',
  },
  {
    title: 'Support for modern & classic pages',
    description:
      'Employee Directory works on both modern and classic pages. On modern pages (Communication & Modern Team sites) both single column and full-width modes are supported.',
  },
];

export const images: Array<{ src: string, width: number, height: number }> = [
  {
    src: '/images/user-directory/ed-01-min.jpeg',
    height: 1099,
    width: 1479,
    title: 'User Directory - 1',
  },
  {
    src: '/images/user-directory/ed-02-min.jpeg',
    height: 1333,
    width: 1706,
    title: 'User Directory - 2',
  },
  {
    src: '/images/user-directory/ed-03-min.jpeg',
    height: 1288,
    width: 1689,
    title: 'User Directory - 3',
  },
  {
    src: '/images/user-directory/ed-04-min.jpeg',
    height: 976,
    width: 1496,
    title: 'User Directory - 4',
  },
  {
    src: '/images/user-directory/ed-10-min.jpeg',
    height: 1086,
    width: 501,
    title: 'User Directory - 5',
  },
  {
    src: '/images/user-directory/ed-09-min.jpeg',
    height: 1326,
    width: 643,
    title: 'User Directory - 6',
  },
  {
    src: '/images/user-directory/ed-05-min.jpeg',
    height: 1259,
    width: 1385,
    title: 'User Directory - 7',
  },
  {
    src: '/images/user-directory/ed-06-min.jpeg',
    height: 1331,
    width: 1694,
    title: 'User Directory - 8',
  },
  {
    src: '/images/user-directory/ed-07-min.jpeg',
    height: 957,
    width: 1386,
    title: 'User Directory - 9',
  },
];
